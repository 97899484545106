import React from 'react';
import './headfooter.css';
import '../body.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HexIcon from '../HexIcon/HexIcon';


//PIE DE PÁGINA

const Footer = () => {
  const thisYear = new Date().getFullYear();

  return (
    <span>
      <div className="footer-container">
        <div className="footer">

          <div className='logo-labux-container protected'>
            <img className="logo-labux-i" src={require('../../Images/Logos/logo-labux-l.svg').default} alt="Logo LabUX"/>
            <div className='logo-labux-t'>
              Laboratorio de Experiencia Usuaria
            </div>
          </div>

          <div className="footer-content">
            <p className="footer-title">¿Dónde estamos?</p>
            <p className="footer-subtitle">Campus Casa Central Valparaíso</p>
            <a target="_blank" rel="noreferrer" className="footer-info" href="https://goo.gl/maps/qAM3V77MVdyhmjjo6">
              Los Placeres 466, Valparaíso <FontAwesomeIcon icon="fa-solid fa-location-dot"/>
            </a>
            <p className='footer-info'>Edificio P, sala P102</p>
          </div>
          <div className="footer-content">
            <p className="footer-title">Contáctanos</p>
            <p className="footer-subtitle">
              <a href='mailto:labux@inf.utfsm.cl' className="footer-subtitle">
                labux@inf.utfsm.cl <FontAwesomeIcon icon="fa-solid fa-envelope"/>
              </a>
            </p>
            <p className="footer-subtitle">
              <a href='mailto:labux.usm@gmail.com' className="footer-subtitle">
                labux.usm@gmail.com <FontAwesomeIcon icon="fa-solid fa-envelope"/>
              </a>
            </p>
            <div className='socials'>

              <a target="_blank" rel="noreferrer"  href="https://www.instagram.com/lab.ux.usm/">
                <HexIcon
                  width='22'
                  fill='white'
                  icon='fa-brands fa-instagram'
                  transform='shrink-2'
                  color='#30343F'
                />
              </a>

              <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/labux-usm-cc/">
                <HexIcon
                  width='22'
                  fill='white'
                  icon='fa-brands fa-linkedin-in'
                  transform='shrink-2'
                  color='#30343F'
                />
              </a>

            </div>
          </div>
        </div>
      </div>

      <div className='footer-second'>
          Laboratorio de Experiencia Usuaria <>&bull;</> {thisYear ? thisYear : 2025}
      </div>
    </span>
  );
};

export default Footer;
