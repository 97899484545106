import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import areas from './areas.json';
import labInfo from './LabInfo';
import loading from '../../Images/LabImages/Loading.png';
import '../body.css';
import './labimages.css';

const LabImages = () => {

  const [mapToShow, setMapToShow] = useState('00');
  const [mapSelected, setMapSelected] = useState('00');
  const [mapLast, setMapLast] = useState('00');
  const [loaded, setLoaded] = useState(false);
  const [inArea, setInArea] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y:0 });
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const mapSection = useRef();

  function handleOnMouseEnter (e) {
    setInArea(true);
    setMapToShow(e.target.id);
    if (mapSelected === '00'){
      setMapLast(e.target.id);
    }
  }

  function handleOnMouseLeave () {
    setInArea(false);
  }

  function handleOnClick (e) {
    e.preventDefault();
    let areaClicked = e.target.id;
    if (e.target.id === '') {
      return;
    }
    if (labInfo[mapSelected].map === labInfo[areaClicked].map){
      setMapToShow('00');
      setMapSelected('00');
    } else {
      setLoaded(false);
      if (mapSelected === '00') {
        window.scrollTo({
          top:
            mapSection.current.parentElement.getBoundingClientRect().top +
            window.scrollY -
            (window.innerWidth >= 1000 ? 140 : 90),
          behavior: 'smooth'
        });
        setLoaded(true);
      }
      setMapToShow(areaClicked);
      setMapSelected(areaClicked);
      setMapLast(areaClicked);
    }
  }

  function handleClickOutside (e) {
    e.preventDefault();
    setMapSelected('00');
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect (()=>{
    if(!inArea) {
      setMapToShow(mapSelected);
    }
  }, [inArea, mapSelected]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
      <div className='map-container' ref={mapSection}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1847 1036"
          className='svg-area'
        >
          <image
            xlinkHref={labInfo[mapToShow].map}
            alt={'Mapa del laboratorio'}
            className="protected"
          />
          <a
            xlinkHref="#"
            href="/"
            onClick={handleClickOutside}
          >
            <polygon
              className='map-area'
              points={[0,0 , 0,1036 ,  1847, 1036, 1847,0]}
              style={{ opacity: 0, cursor: mapSelected === '00' ? 'default' : 'pointer' }}
            />
          </a>
          {areas.map(a => (
            <a
              xlinkHref="#"
              href="/"
              id={a.id}
              key={a.id}
              onClick={handleOnClick}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            >
              <polygon
                id={a.id}
                className='map-area'
                points={a.coords}
                alt={labInfo[a.id].title}
                style={{ opacity: 0 }}
              />
            </a>
          ))}
        </svg>

        <div className='chv-container'>
          <FontAwesomeIcon
            icon="fa-solid fa-chevron-left"
            style={{ display: mapSelected === '00' ? 'inline' : 'none' }}
            className="chevron chv-left"
          />
          <FontAwesomeIcon
            icon="fa-solid fa-chevron-up"
            style={{ display: mapSelected === '00' ? 'inline' : 'none' }}
            className="chevron chv-up"
          />
        </div>
        <div className='map-info' style={mapSelected === '00' ? { justifyContent: 'center' } : {}} >
          {mapSelected === '00' ? '' :
            <p className='map-info-sala'>
              {labInfo[mapSelected].sala}
            </p>
          }

          <p className='map-info-title'>
            {labInfo[mapSelected].title}
            {mapSelected === '00'? 'Haz click en el mapa para conocer los espacios del Laboratorio' : ''}
          </p>

          <div className={mapSelected === '00' ? 'map-info-content inactive-2' : 'map-info-content'}>
            <img
              className={mapSelected === '00' ? 'map-info-img inactive-1' : 'map-info-img protected'}
              style={{ display: loaded? 'inline': 'none' }}
              src={labInfo[mapLast].img}
              alt={'Imagen de ' + labInfo[mapToShow].title}
              onLoad={() => {setLoaded(true);}}
            />
            <img
              className={mapSelected === '00' ? 'map-info-img inactive-1' : 'map-info-img'}
              style={{ display: loaded ? 'none': 'inline' }}
              src={loading}
              alt={''}
            />
            <div className='map-info-text'> {labInfo[mapLast].info} </div>
          </div>
        </div>
      </div>

      <span className='map-tooltip'
        style={{
          top: mousePos.y - 26,
          visibility: inArea && mapToShow !== '00' ? 'visible' : 'hidden'
        }}
      >
        <span className='map-tooltext'
          style={{
            left: mousePos.x - windowSize.innerWidth/2 + 6,
          }}
        >
          {labInfo[mapToShow].title}
        </span>
      </span>
    </>
  );
};

export default LabImages;
