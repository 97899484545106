import React from 'react';
import '../body.css';
import './services.css';

const userServices = [
  {
    title: 'estudio de usuarios',
    description: 'Análisis de los usuarios o potenciales usuarios que interactúan con un producto.',
    image: require('../../Images/Servicios/estudio-usuario.png'),
  },
  {
    title: 'definición de usuarios',
    description: 'Definición de arquetipos de usuarios reales para quienes se está diseñando.',
    image: require('../../Images/Servicios/definicion-arquetipos.png'),
  },
  {
    title: 'definición de escenarios',
    description: 'Identificación de las tareas y objetivos que los usuarios tienen al usar un producto o servicio.',
    image: require('../../Images/Servicios/escenario-usuario.png'),
  },
  {
    title: 'user journey map',
    description:
      `Visualización de las fases que sigue una persona en su interacción con un producto o servicio de principio a fin,
      analizando sus emociones, puntos de acción, etc.`,
    image: require('../../Images/Servicios/user-journey-map.png'),
  },
];

const testServices = [
  {
    title: 'prueba de usabilidad',
    description:
      `Evaluación de un producto con usuarios reales, a quienes se les entregan tareas
      a realizar previamente preparadas.`,
    image: require('../../Images/Servicios/test-usabilidad.png'),
  },
  {
    title: 'evaluación heurística',
    description:
      `Identificación de errores y problemas de usabilidad, para los cuales 
      se entregan recomendaciones para solucionarlos.`,
    image: require('../../Images/Servicios/evaluacion-heuristica.png'),
  },
  {
    title: 'eye tracking',
    description:
      `Utilización de un lente de seguimiento ocular para identificar donde los usuarios centran 
      su atención al utilizar una interfaz o producto.`,
    image: require('../../Images/Servicios/eye-tracking.png'),
  },
];


const Services = () => {
  return (
    <>
      <div id="servicios" className="header-backg">
        <h1>
          Servicios
        </h1>
      </div>
      <div className="main-body">
        <div className="content-container" >
          <p className="titulo" id='que-ofrecemos'>
            <a href='#que-ofrecemos' className='section-title'>Qué ofrecemos</a>
          </p>
          <div className="services-container">
            {/*Usuarios*/}
            <div className='service-column'>
              <span style={{ width: '100%' }}>
                <img
                  className="services-picture protected"
                  src={require('../../Images/Servicios/usuarios.png')}
                  alt="Ícono de diseño interacción"
                />
                <p className="services-title">INVESTIGACIÓN DE USUARIOS</p>
              </span>
              {userServices.map( (service, index) => (
                <div className="en-linea espaciado" key={index}>
                  <img
                    className="services-subpicture protected"
                    src={service.image}
                    alt={'Ícono de ' + service.title}
                  />
                  <div className="margin-left-20">
                    <div className="services-subtitle orange">{service.title}</div>
                    <p className="contenido">
                      {service.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            {/*Pruebas*/}
            <div className='service-column'>
              <span style={{ width: '100%' }}>
                <img
                  className="services-picture protected"
                  src={require('../../Images/Servicios/pruebas.png')}
                  alt="Ícono de diseño investigación"
                />
                <p className="services-title">PRUEBA DE PROTOTIPOS Y PRODUCTOS</p>
              </span>
              {testServices.map( (service, index) => (
                <div className="en-linea espaciado" key={index}>
                  <img
                    className="services-subpicture protected"
                    src={service.image}
                    alt={'Ícono de ' + service.title}
                  />
                  <div className="margin-left-20">
                    <div className="services-subtitle green">{service.title}</div>
                    <p className="contenido">
                      {service.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/*
        <div className="content-container">
          <p className="titulo" id='nuestro-proceso' >
            <a href='#nuestro-proceso' className='section-title'>Nuestro proceso</a>
          </p>
          Contenido
        </div>

        <div className="content-container">
          <p className="titulo" id='resultados'>
            <a href='#resultados' className='section-title'>Resultados o clientes previos</a>
          </p>
          Contenido
        </div>
        */}
      </div>
    </>
  );
};

export default Services;