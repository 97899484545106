const boardMembers = [
  {
    name: 'Cecilia Reyes',
    title: 'Profesora',
    position: 'Co-directora Lab UX CC',
    email: 'reyes@inf.utfsm.cl',
    linkedin: 'https://www.linkedin.com/in/cecilia-reyes-covarrubias/',
    img: require('../../Images/Equipo/Profile-CeciliaReyes.jpg')
  },
  {
    name: 'Claudia Lopez',
    title: 'Profesora',
    position: 'Co-directora Lab UX CC',
    email: 'claudia.lopez@inf.utfsm.cl',
    linkedin: 'https://www.linkedin.com/in/claudia-lopez-0218164/',
    img: require('../../Images/Equipo/Profile-ClaudiaLopez.jpg')
  },
  {
    name: 'Liubov Dombrovskaia',
    title: 'Profesora',
    position: 'Directora Lab UX SJ',
    email: 'liuba@inf.utfsm.cl',
    linkedin: 'https://www.linkedin.com/in/lioubov/',
    img: require('../../Images/Equipo/Profile-LiubovDombrovskaia.png')
  },
  {
    name: 'Leonardo Madariaga',
    title: 'Profesor',
    position: 'Asesor IDP',
    email: 'leonardo.madariaga@usm.cl',
    linkedin: 'https://www.linkedin.com/in/leonardomadariaga/',
    img: require('../../Images/Equipo/Profile-LeonardoMadariaga.jpg'),
  },
];

export default boardMembers;