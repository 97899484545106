import React from 'react';
import '../body.css';
import './learnmore.css';

/*SECCION INICIO*/

const LearnMore = () => {

  const thirdParagraph = <span>
  Para una buena <b>Experiencia Usuaria</b>, se deben balancear los objetivos y <b>necesidades de los
  Usuarios</b> que utilizan un producto o servicio y los propios <b>objetivos del Negocio</b>, utilizando
  la <b>Tecnología</b> de manera estratégica para suplir dichas necesidades y cumplir ambos objetivos.
  </span>;

  return (
    <>
      <div id="que-es-ux" className="header-backg">
        <h1>
          ¿Qué es UX?
        </h1>
      </div>
      <div className="main-body">
        <div className="content-container">
          <p className="titulo" id='experencia-usuaria'>
            <a href='#experencia-usuaria' className='section-title'>Experiencia Usuaria</a>
          </p>
          <div className="queesux-container">
            <div>
              <p className="contenido">
                La <b>Experiencia Usuaria</b> (<b>UX</b>, del inglés <i>User Experience</i>) es el área interdisciplinaria
                que estudia el conjunto de factores que repercuten en la <b className='orange'>interacción</b> con un producto,
                servicio o sistema por parte de <b className='orange'>usuarios</b> (Hassan-Montero, 2015). La experiencia que
                éste tenga está determinada por las características del propio <u>usuario</u>, del <u>producto</u> y
                el <u>contexto</u> en el que ocurre su interacción (Hassenzahl & Tractinsky, 2006).
              </p>
              <p className="contenido">
                La norma <b>ISO 9241</b> sobre diseño centrado en las personas define UX como <i>&quot;las <u>percepciones
                y respuestas</u> de una persona como resultado del uso o de la anticipación del uso de un producto,
                sistema o servicio&quot;</i>. <b>UX</b> incluye todas las emociones, creencias, preferencias, percepciones,
                respuestas físicas y fisiológicas, comportamientos y logros de los usuarios que ocurren <b className='orange'>
                antes, durante y después del uso</b> (Organización Internacional de Normalización, 2019).
              </p>
              <p className='contenido text-desktop'>
                {thirdParagraph}
              </p>
            </div>
            <img
              className="queesux-image"
              src={require('../../Images/LearnMore/Que-es-UX.png')}
              alt={`
                Elementos que interactuán en UX: Usuarios y sus necesidades, Negocios y sus objetivos y las 
                oportunidades que brinda la Tecnología.
              `}
            />
            <p className='contenido text-mobile'>
              {thirdParagraph}
            </p>
          </div>
        </div>

        <div className="content-container">
          <p className="titulo" id='dimensiones-de-ux'>
            <a href='#dimensiones-de-ux' className='section-title'>Dimensiones de UX</a>
          </p>

          <div className="facets-container">
            <img
              className="facets-image"
              src={require('../../Images/LearnMore/Facetas-UX.png')}
              alt={`
                Las 7 dimensiones de UX según Honeycomb UX: Útil, Deseable, Utilizable, 
                Valioso, Accesible, Encontrable y Creíble
                `}
            />
            <div>
              <p className="contenido">
                  Existen diferentes dimensiones o atributos para evaluar la <b>calidad de la Experiencia Usuaria</b> que
                  entrega un producto o servicio. El modelo <b>Honeycomb UX</b> que se utiliza  en el Lab UX USM
                  establece <b>7 dimensiones</b> que debe tener un producto diseñado con un foco en sus usuarios
                  (Reyes Gómez, 2022):
              </p>
              <div className="contenido">
                <ul className='facets-list'>
                  <li>
                    <b className='gray'>Útil</b>: logra satisfacer una necesidad real.
                  </li>
                  <li>
                    <b className='orange'>Deseable</b>: es estética y visualmente atractivo.
                  </li>
                  <li>
                    <b className='blue'>Utilizable</b>: es simple y fácil de usar.
                  </li>
                  <li>
                    <b className='gold'>Valioso</b>: ofrece un valor añadido.
                  </li>
                  <li>
                    <b className='green'>Accesible</b>: puede ser utilizado por todo público.
                  </li>
                  <li>
                    <b style={{ color: 'var(--color-red)' }}>Encontrable</b>: tiene facilidad para encontrar lo que se necesita.
                  </li>
                  <li>
                    <b className='dark'>Creíble</b>: genera confianza en los usuarios.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Metodos UX para próxima actualización
        <div className="content-container">
          <p className="titulo" id='metodos-ux'>
            <a href='#metodos-ux' className='section-title'>Métodos UX</a>
          </p>
          <div id="queesux-container">
            <div className="en-linea espaciado centrado-vertical">
              <div>
                <p className="contenido">
                    Explicación
                </p>
                <p className="contenido">
                    Algunos ejemplos y explicar como en el lab se usan
                </p>
                <p className="contenido">
                    Foto de alguna sesión de testing
                </p>
              </div>
            </div>
          </div>
        </div>
         */}
      </div>
    </>
  );
};

export default LearnMore;