import lab from '../../Images/LabImages/M_laboratorio.png';

import col from '../../Images/LabImages/M_colab_colaborativo.png';
import ent from '../../Images/LabImages/M_colab_entrevistas.png';
import caf from '../../Images/LabImages/M_colab_cafeteria.png';
import seg from '../../Images/LabImages/M_obs_seguimiento.png';
import mon from '../../Images/LabImages/M_obs_monitoreo.png';
import usr from '../../Images/LabImages/M_test_usuarios.png';
import vid from '../../Images/LabImages/M_test_videojuegos.png';

// Las imagenes de "info" deben tener una proporción ancho:alto de 3:2, de calidad mínima 3000x2000 pixeles
import colInfo from '../../Images/LabImages/I_colab_colaborativo.jpg';
import entInfo from '../../Images/LabImages/I_colab_entrevistas.jpg';
import cafInfo from '../../Images/LabImages/I_colab_cafeteria.jpg';
import segInfo from '../../Images/LabImages/I_obs_seguimiento.jpg';
import monInfo from '../../Images/LabImages/I_obs_monitoreo.jpg';
import usrInfo from '../../Images/LabImages/I_test_usuarios.jpg';
import vidInfo from '../../Images/LabImages/I_test_videojuegos.jpg';

const labInfo = {
  '00': {
    'title': '',
    'map': lab,
    'img': lab,
    'info': '',
    'sala': '',
  },
  '01': {
    'title': 'Zona de trabajo colaborativo',
    'map': col,
    'img': colInfo,
    'info': 'Un espacio ambientado para el trabajo en equipo. En él se pueden realizar ayudantías, clases y talleres.',
    'sala': 'Sala Colaborativa',
  },
  '02': {
    'title': 'Zona de entrevistas',
    'map': ent,
    'img': entInfo,
    'info': 'Un sector de la Sala Colaborativa ideal para entrevistar usuarios o trabajar de manera individual.',
    'sala': 'Sala Colaborativa',
  },
  '03': {
    'title': 'Cafetería',
    'map': caf,
    'img': cafInfo,
    'info': 'El Lab UX cuenta con un espacio apto para preparar y servir café y otros alimentos para eventos o actividades.',
    'sala': 'Sala Colaborativa',
  },
  '04': {
    'title': 'Área de seguimiento',
    'map': seg,
    'img': segInfo,
    'info': 'Área que cuenta con sillones y monitores para observar las actividades que se realizan en el Laboratorio.',
    'sala': 'Sala de Observación',
  },
  '06': {
    'title': 'Área de monitoreo',
    'map': mon,
    'img': monInfo,
    'info': 'Puestos para controlar las cámaras y micrófonos del Laboratorio y observar hacia la Sala de Testing.',
    'sala': 'Sala de Observación',
  },
  '07': {
    'title': 'Área de testing con usuarios',
    'map': usr,
    'img': usrInfo,
    'info': 'Espacio aislado para realizar investigaciones con usuarios. Cuenta con cámaras, micrófonos y un vidrio-espejo.',
    'sala': 'Sala de Testing',
  },
  '08': {
    'title': 'Área de testing de videojuegos',
    'map': vid,
    'img': vidInfo,
    'info': 'Parte de la Sala de Testing ambientada para probar videojuegos, con un sillón acolchado y un monitor móvil.',
    'sala': 'Sala de Testing',
  },
};

//Se definen dos áreas con distinto ID en areas.json para el área de seguimiento, por lo tanto se necesita hacer lo siguiente
labInfo['05'] = labInfo['04'];

export default labInfo;