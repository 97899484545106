// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Qué es UX*/
.queesux-container{
  display: flex;
  gap: 40px;
  align-items: center;
}

.queesux-image{
  width: 280px;
  margin-right: auto;
  margin-left: auto;
}

.text-mobile{
  display: none;
  visibility: hidden;
}

/* Dimensiones de UX */
.facets-container{
  display: flex;
  align-items: center;
  gap: 40px;
}

.facets-image{
  width: 300px;
}

.facets-list{
  column-count: 2;
  column-gap: 60px;
  text-align: left;
}

@media (max-width: 1250px) {
  .queesux-container {
    gap: 20px;
    flex-direction: column;
    justify-content: center;
  }

  .text-mobile{
    display: inline;
    visibility: visible;
  }
  
  .text-desktop{
    display: none;
    visibility: hidden;
  }
  
  .facets-list{
    column-count: 1;
  }
}

@media (max-width: 700px){
  .facets-container{
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LearnMore/learnmore.css"],"names":[],"mappings":"AAAA,aAAa;AACb;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA,sBAAsB;AACtB;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;IACE,SAAS;IACT,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":["/* Qué es UX*/\n.queesux-container{\n  display: flex;\n  gap: 40px;\n  align-items: center;\n}\n\n.queesux-image{\n  width: 280px;\n  margin-right: auto;\n  margin-left: auto;\n}\n\n.text-mobile{\n  display: none;\n  visibility: hidden;\n}\n\n/* Dimensiones de UX */\n.facets-container{\n  display: flex;\n  align-items: center;\n  gap: 40px;\n}\n\n.facets-image{\n  width: 300px;\n}\n\n.facets-list{\n  column-count: 2;\n  column-gap: 60px;\n  text-align: left;\n}\n\n@media (max-width: 1250px) {\n  .queesux-container {\n    gap: 20px;\n    flex-direction: column;\n    justify-content: center;\n  }\n\n  .text-mobile{\n    display: inline;\n    visibility: visible;\n  }\n  \n  .text-desktop{\n    display: none;\n    visibility: hidden;\n  }\n  \n  .facets-list{\n    column-count: 1;\n  }\n}\n\n@media (max-width: 700px){\n  .facets-container{\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
