// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stakeholder-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.stakeholder-item{
  display: flex;
  max-width: 920px;
  margin: 0 auto;
  gap: 40px;
}

.stakeholder-picture{
  display: block;
  border-radius: 50%;
  object-fit: cover;
  width: 180px;
  height: 180px;
  margin: auto;
}

.stakeholder-name{
  text-align: left;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.colaboramos-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
}

.logo-scotiabank{
  height: 60px;
  margin: auto 0;
}

.logo-idp, .logo-di{
  height: 100px;
}

@media (max-width: 1000px) {
  .colaboramos-container{
    flex-direction: column;
  }
  .stakeholder-name{
    font-size: 20px;
  }
  .stakeholder-picture{
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 700px) {
  .stakeholder-item {
    flex-direction: column;
    gap: 10px
  }
  .stakeholder-name{
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Stakeholders/stakeholders.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,cAAc;EACd,SAAS;AACX;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,eAAe;EACjB;EACA;IACE,YAAY;IACZ,aAAa;EACf;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB;EACF;EACA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".stakeholder-container{\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.stakeholder-item{\n  display: flex;\n  max-width: 920px;\n  margin: 0 auto;\n  gap: 40px;\n}\n\n.stakeholder-picture{\n  display: block;\n  border-radius: 50%;\n  object-fit: cover;\n  width: 180px;\n  height: 180px;\n  margin: auto;\n}\n\n.stakeholder-name{\n  text-align: left;\n  font-size: 25px;\n  font-weight: 500;\n  letter-spacing: 0.1px;\n}\n\n.colaboramos-container{\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  gap: 30px;\n}\n\n.logo-scotiabank{\n  height: 60px;\n  margin: auto 0;\n}\n\n.logo-idp, .logo-di{\n  height: 100px;\n}\n\n@media (max-width: 1000px) {\n  .colaboramos-container{\n    flex-direction: column;\n  }\n  .stakeholder-name{\n    font-size: 20px;\n  }\n  .stakeholder-picture{\n    width: 150px;\n    height: 150px;\n  }\n}\n\n@media (max-width: 700px) {\n  .stakeholder-item {\n    flex-direction: column;\n    gap: 10px\n  }\n  .stakeholder-name{\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
