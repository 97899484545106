import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../body.css';
import './equipo.css';
import boardMembers from './TeamInfo';
import HexIcon from '../HexIcon/HexIcon';

const Equipo = () => {
  const [copytext, setCopytext] = useState('Copiar correo');
  const [email, setEmail] = useState(null);

  function handleClick(email) {
    navigator.clipboard.writeText(email);
    setCopytext('Copiado');
    setEmail(email);
  }

  function handleMouseEnter() {
    setEmail(null);
    setCopytext('Copiar correo');
  }

  useEffect(()=>{
    let isMounted = true;
    if (email) {
      setTimeout(() => {
        if (!isMounted) return;
        setEmail(null);
      }, 1200);
    }
  }, [email]);

  return (
    <>
      <div id='equipo' className='header-backg'>
        <h1>
          Equipo y Contacto
        </h1>
      </div>
      <div className='main-body'>
        <div id='equipo-container' className='content-container'>
          <p className='titulo' id='directorio'>
            <a href='#directorio' className='section-title'>Directorio</a>
          </p>
          <div className='profiles-container'>
            {boardMembers.map( (member, index) => (
              <div className='profile' key={index}>
                <div className='profile-picture-container'>
                  <a target='_blank' rel='noopener noreferrer' href={member.linkedin}>
                    <img className='profile-picture' src={member.img} alt={member.title + ' ' + member.name}/>
                    <div className='overlay'>
                      <div className='fade'>
                        <i className='fab fa-linkedin'/>
                      </div>
                    </div>
                  </a>
                  <div className='profile-data'>
                    <p><b>{member.name}</b></p>
                    <p>{member.position}</p>
                    <p
                      className='email tt-container'
                      onClick={()=>{handleClick(member.email);}}
                      onMouseEnter={handleMouseEnter}
                    >
                      {member.email} <FontAwesomeIcon icon='fa-regular fa-copy' />
                      <span
                        className={email === member.email ? 'tt-tooltip tt-visible protected' : 'tt-tooltip'}
                        style={{ bottom: '-40px' }}
                      >
                        {copytext}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div id='equipo-container' className='content-container'>
          <p className='titulo' id='contacto' style={{ marginBottom: '20px' }} >
            <a href='#contacto' className='section-title'>Contacto</a>
          </p>
          <div className='contenido contact-content'>

            <div className='contact-map'>
              <iframe
                // eslint-disable-next-line max-len
                src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13379.027228569266!2d-71.594447!3d-33.036537!3m2!1i1024!2i768!4f4.1!3m3!1m2!1s0x9689e1105bd337c5%3A0x66a21e3e04d716ec!2sUniversidad%20T%C3%A9cnica%20Federico%20Santa%20Mar%C3%ADa%20Edificio%20P!5e0!3m2!1sen!2scl!4v1733158256746!5m2!1sen!2scl'
                className='map-iframe'
                allowFullScreen=''
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
              />
            </div>

            <div className='contact-info'>

              <p>Visítanos</p>
              <div className='contact-line'>
                <HexIcon
                  width='40'
                  fill='#005E90'
                  icon='fa-solid fa-location-dot'
                  size='xl'
                  color='white'
                />
                <div className='contact-line-data'>
                  <p>Dirección</p>
                  Universidad Técnica Federico Santa María, Edificio P, <u>sala P102</u>, Los Placeres 466, Valparaíso.
                </div>
              </div>
              <div className='contact-line'>
                <HexIcon
                  width='40'
                  fill='#005E90'
                  icon='fa-solid fa-clock'
                  size='lg'
                  color='white'
                />
                <div className='contact-line-data'>
                  <p>Horario de atención</p>
                  Lunes a Viernes de <u>11:05 a 13:40</u> horas y de <u>14:40 a 18:40</u> horas.
                </div>
              </div>

              <p>Contáctanos</p>
              <div className='contact-line'>
                <span onClick={()=>{handleClick('coordinacion.labux@inf.utfsm.cl');}}>
                  <HexIcon
                    width='40'
                    fill='#005E90'
                    icon='fa-solid fa-user'
                    size='lg'
                    color='white'
                  />
                </span>
                <div className='contact-line-data'>
                  <p>Ayudante coordinador</p>
                  <div>Para consultas sobre servicios y proyectos.</div>
                  <div
                    className='email tt-container'
                    onClick={()=>{handleClick('coordinacion.labux@inf.utfsm.cl');}}
                    onMouseEnter={handleMouseEnter}
                    style={{ width: 'fit-content' }}
                  >
                    coordinacion.labux@inf.utfsm.cl <FontAwesomeIcon icon='fa-regular fa-copy' />
                    <span
                      className={email === 'coordinacion.labux@inf.utfsm.cl' ? 'tt-tooltip tt-visible protected' : 'tt-tooltip'}
                      style={{ bottom: '-32px' }}
                    >
                      {copytext}
                    </span>
                  </div>
                </div>
              </div>
              <div className='contact-line'>
                <span onClick={()=>{handleClick('labux@inf.utfsm.cl');}}>
                  <HexIcon
                    width='40'
                    fill='#005E90'
                    icon='fa-solid fa-envelope'
                    size='lg'
                    color='white'
                  />
                </span>
                <div className='contact-line-data'>
                  <p>Equipo de ayudantes</p>
                  <div>Para consultas y solicitudes generales.</div>
                  <div
                    className='email tt-container'
                    onClick={()=>{handleClick('labux@inf.utfsm.cl');}}
                    onMouseEnter={handleMouseEnter}
                    style={{ width: 'fit-content' }}
                  >
                    labux@inf.utfsm.cl <FontAwesomeIcon icon='fa-regular fa-copy' />
                    <span
                      className={email === 'labux@inf.utfsm.cl' ? 'tt-tooltip tt-visible protected' : 'tt-tooltip'}
                      style={{ bottom: '-32px' }}
                    >
                      {copytext}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Equipo;