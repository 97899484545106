import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from './components/Home/Home';
import LearnMore from './components/LearnMore/LearnMore';
import Stakeholders from './components/Stakeholders/Stakeholders';
import Services from './components/Services/Services';
import AboutUs from './components/AboutUs/AboutUs';
import Equipo from './components/Equipo/Equipo';
import Footer from './components/HeadandFoot/Footer';
//import Schedule from './components/Schedule/Schedule';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Header from './components/HeadandFoot/Header';

library.add(fab, fas, far);

function App() {
  return (
    <>
      <Header/>
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route path='/que-es-ux' component={LearnMore}/>
        <Route path='/comunidad' component={Stakeholders}/>
        <Route path='/servicios' component={Services}/>
        <Route path='/sobre-nosotros' component={AboutUs}/>
        <Route path='/equipo' component={Equipo}/>
        <Route render={() => <Redirect to={{ pathname: '/' }} />} />
        { /* Seccion eliminada <Route path='/schedule' component={Schedule}/>*/}
      </Switch>
      <Footer/>
    </>
  );
}

export default App;
