import React, { useState, useEffect } from 'react';
import LabImages from '../LabImages/LabImages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../body.css';
import './home.css';

const metrics = [
  { metric: 'students', value: 800, text: 'Estudiantes\nalcanzados' },
  { metric: 'thesis', value: 20, text: 'Memoristas\nbeneficiados' },
  { metric: 'proyects', value: 6, text: 'Proyectos\nrealizados' },
  { metric: 'assistants', value: 30, text: 'Ayudantes\ncapacitados' },
];

const Home = () => {
  const [values, setValues] = useState([0,0,0,0]);

  useEffect (() => {
    let isMounted = true;
    setTimeout(() => {
      if (!isMounted) {
        return;
      }
      let aux = values.map((v,i) => {
        let targetV = metrics[i].value;
        let toSum = targetV > 100 ? 27 : (targetV < 20 ? .5 : 1);
        if (v < targetV)
          return Math.min(v + toSum, targetV);
        else
          return v;
      });
      if (JSON.stringify(aux) !== JSON.stringify(values)){
        setValues(aux);
      }
    }, 55);
    return () => {
      isMounted = false;
    };
  }, [values]);

  return (
    <>
      <div className='intro-container'>
        <div id='home' className='header-backg home-banner'>
          <img
            className='logo-banner-i protected'
            src={require('../../Images/Logos/logo-labux-texto-2.svg').default}
            alt='Logo Lab UX Laboratorio de Experiencia Usuaria'/
          >
          <p>Innovación tecnológica para la investigación centrada en las personas</p>
        </div>
        <div className='metrics-container'>
          <div className='metrics'>
            {metrics.map( (m, index) => (
              <div className='metric' key={m.metric}>
                <p>{Math.floor(values[index])}+</p>
                {m.text}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='main-body'>
        <div className='content-container home-container'>
          {/*<p className='titulo'>Lab UX USM</p>*/}
          <div className='home-content' style={{ marginBottom: '60px' }}>
            <div className='drop-shadow'>
              <img
                src={require('../../Images/Home/home.jpg')}
                className='home-img'
                alt='Foto de gente utilizando la Sala Colaborativa del Laboratorio'
              />
            </div>
            <div className='contenido home-text'>
              <span>
                El <b>Lab UX USM</b> es un laboratorio abierto a la comunidad universitaria,
                para que <b className='blue'>estudiantes, memoristas y profesores</b> puedan
                llevar a cabo proyectos e investigaciones en los cuales los <b>usuarios sean siempre el foco</b>.
                <br/>
              </span>
              <a href='/comunidad' className='home-button-cont'>
                <div className='home-button' style={{ backgroundColor: 'var(--color-blue)' }}>
                  Ir a Comunidad
                  <FontAwesomeIcon
                    icon='fa-solid fa-chevron-right'
                    color='white'
                  />
                </div>
              </a>
            </div>
          </div>
          <div className='home-content reverse ' style={{ marginBottom: '60px' }}>
            <div className='drop-shadow'>
              <img
                src={require('../../Images/Home/uso-lab.jpg')}
                className='home-img'
                alt='Foto de alumnos utilizando la Sala de Observación'
              />
            </div>
            <div className='contenido home-text'>
              <span>
                Nuestro equipo de ayudantes de laboratorio se encuentran capacitados en diversos <b>Métodos UX</b>,
                como <b className='orange'>Pruebas de Usabilidad con Usuarios</b>,
                facilitación de <b className='orange'>Focus Groups</b>, entre otros.
                El conocimiento que adquieren es luego compartido con la comunidad estudiantil.
                <br/>
              </span>
              <a href='/que-es-ux' className='home-button-cont'>
                <div className='home-button' style={{ backgroundColor: 'var(--color-orange)' }}>
                  Aprende sobre UX
                  <FontAwesomeIcon
                    icon='fa-solid fa-chevron-right'
                    color='white'
                  />
                </div>
              </a>
            </div>
          </div>
          <div className='home-content'>
            <div className='drop-shadow'>
              <img
                src={require('../../Images/Home/proyectos.png')}
                style={{ objectPosition: '20% 0%' }}
                className='home-img'
                alt='Foto de ayudantes de laboratorio en una reunión de proyecto'
              />
            </div>
            <div className='contenido home-text'>
              <span>
                El Lab UX USM ofrece <b>Servicios de UX</b> para empresas e instituciones
                públicas que deseen <b>evaluar la experiencia</b> que ofrecen sus tecnologías, productos o servicios.
                Trabajamos con proyectos en <b className='green'>etapas de ideación hasta
                productos finalizados</b>.
                <br/>
              </span>
              <a href='/servicios' className='home-button-cont'>
                <div className='home-button' style={{ backgroundColor: 'var(--color-green)' }}>
                  Conoce Nuestros Servicios
                  <FontAwesomeIcon
                    icon='fa-solid fa-chevron-right'
                    color='white'
                  />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className='content-container'>
          <p className='titulo' id='mapa-interactivo'>
            <a href='#mapa-interactivo' className='section-title'>Mapa Interactivo</a>
          </p>
          <LabImages/>
        </div>
      </div>
    </>
  );
};

export default Home;