// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hex-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .fa-icon{
    position: absolute;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/HexIcon/hexicon.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".hex-icon {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  .fa-icon{\n    position: absolute;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
