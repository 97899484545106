import React from 'react';
import '../body.css';
import './stakeholders.css';

/*SECCION CON QUIENES TRABAJAMOS*/

const Stakeholders = () => {
  return (
    <>
      <div id="comunidad" className="header-backg">
        <h1>
            Comunidad
        </h1>
      </div>
      <div className="main-body">
        <div className="content-container">
          <p className="titulo" id='con-quien-trabajamos'>
            <a href='#con-quien-trabajamos' className='section-title'>Con quién trabajamos</a>
          </p>
          <div className="stakeholder-container">
            <div className="stakeholder-item">
              <img
                className="stakeholder-picture protected"
                src={require('../../Images/Comunidad/Docencia.png')}
                alt="Imagen docencia"
              />
              <div>
                <p className="stakeholder-name orange"> Docencia </p>
                <div className="contenido">
                    Lab UX apoya a <b>estudiantes, académicos, memoristas e investigadores </b> en el
                    diseño y evaluación de UX para sus actividades curriculares y de investigación.
                </div>
              </div>
            </div>
            <div className="stakeholder-item">
              <img
                className="stakeholder-picture protected"
                src={require('../../Images/Comunidad/Sociedad.png')}
                alt="Imagen comunidad"
              />
              <div>
                <p className="stakeholder-name green"> Sociedad Civil </p>
                <div className="contenido">
                    Lab UX busca involucrarse con la <b>sociedad civil e instituciones públicas y
                    educacionales</b> para desarrollar colaboraciones en el ámbito del diseño y evaluaciones
                    centradas en personas de tecnologías y productos.
                </div>
              </div>
            </div>
            <div className="stakeholder-item">
              <img
                className="stakeholder-picture protected"
                src={require('../../Images/Comunidad/Industria.png')}
                alt="Imagen industria"
              />
              <div>
                <p className="stakeholder-name blue"> Industria </p>
                <div className="contenido">
                    Lab UX ofrece servicios de UX, tales como testing de usabilidad, para <b>empresas
                    e instituciones públicas</b> que deseen evaluar la experiencia que ofrecen su tecnologías,
                    productos o servicios.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-container">
          <p className="titulo" id='con-quien-colaboramos'>
            <a href='#con-quien-colaboramos' className='section-title'>Con quién colaboramos</a>
          </p>
          <div className="colaboramos-container">
            <a target="_blank" rel="noreferrer" href="https://www.scotiabankchile.cl/">
              <img
                className="logo-scotiabank"
                src={require('../../Images/Comunidad/logo-scotiabank.png')}
                alt="Logo Scotiabank"
              />
            </a>

            <a target="_blank" rel="noreferrer" href="https://www.did.usm.cl/">
              <img
                className="logo-idp"
                src={require('../../Images/Comunidad/logo-idp.png')}
                alt="Logo departamento de ingeniería en diseño"
              />
            </a>

            <a target="_blank" rel="noreferrer" href="https://informatica.usm.cl/">
              <img
                className="logo-di"
                src={require('../../Images/Comunidad/logo-di.png')}
                alt="Logo departamento de informática"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stakeholders;