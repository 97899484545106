import React from 'react';
import './hexicon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HexIcon = (props) => {

  const { width, fill, icon, transform, color, size } = props;

  return (
    <div className='hex-icon'>
      <svg width={width} viewBox="0 0 320 368" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M159.954 0L319.283 91.9885V275.966L159.954 367.954L0.625 275.966V91.9885L159.954 0Z" fill={fill}/>
      </svg>
      <FontAwesomeIcon className='fa-icon' icon={icon} transform={transform} color={color} size={size} />
    </div>
  );
};

export default HexIcon;