import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../Images/Logos/logo-usm-di.svg';
import './headfooter.css';
import '../body.css';


const links = [
  { url: '/', title: 'Inicio' },
  { url: '/que-es-ux', title: '¿Qué es UX?' },
  { url: '/comunidad', title: 'Comunidad' },
  { url: '/servicios', title: 'Servicios' },
  { url: '/sobre-nosotros', title: 'Sobre Nosotros' },
  { url: '/equipo', title: 'Equipo y Contacto' },
];

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { pathname } = useLocation();

  function handleDropdown() {
    setDropdownOpen(!dropdownOpen);
  }

  function handleClick() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
  }

  useEffect(() => {
    let auxTitle = links.find((link) => link.url === pathname)?.title;
    if (auxTitle) {
      document.title = auxTitle + ' - Lab UX USM';
    }
  }, [pathname]);

  useEffect(()=>{
    if (!dropdownOpen) {
      return;
    }
    window.addEventListener('scroll', handleDropdown);
    return () => {
      window.removeEventListener('scroll', handleDropdown);
    };
  });

  return (
    <div className="menu">
      <div className="header-container">
        <div className="header">
          <div className="breadcrumb">
            <div className='crumb'>
              <a target="_blank" rel="noreferrer" href="https://www.usm.cl"> USM.cl </a>
            </div>
            <div className='crumb'>
              <a target="_blank" rel="noreferrer" href="https://informatica.usm.cl/"> Informática </a>
            </div>
            <div className='crumb'>
              <a href="/"> Lab UX </a>
            </div>
          </div>
          <div className='socials'>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/lab.ux.usm/">
              <span className="fa-layers fa-fw fa-lg">
                <FontAwesomeIcon icon="fa-solid fa-circle" inverse/>
                <FontAwesomeIcon icon="fa-brands fa-instagram" transform="shrink-6 right-0.3" color="#005E90"/>
              </span>
            </a>

            <a target="_blank" rel="noreferrer"  href="https://www.linkedin.com/company/labux-usm-cc/">
              <span className="fa-layers fa-fw fa-lg">
                <FontAwesomeIcon icon="fa-solid fa-circle" inverse/>
                <FontAwesomeIcon icon="fa-brands fa-linkedin-in" transform="shrink-6 right-0.6" color="#005E90"/>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="navbar-container blur">
        <div className="navbar flex-centered">
          <a href="/" style={{ display: 'flex' }} >
            <img className="logo-usm protected" src={logo}  alt="Logo USM"/>
          </a>
          <div className='navbar-options'>
            {links.map( link => (
              <NavLink
                exact
                key={link.url}
                to={link.url}
                onClick={handleClick}
              >
                {link.title}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
      <div className="dropdown-backdrop" style={{ height: dropdownOpen? '100vh' : '0vh' }} onClick={ handleDropdown }/>
      <div className="mobile-header blur flex-centered">
        <a href="/" style={{ display: 'flex' }}>
          <img className="logo-usm-mobile protected" src={logo} alt="Logo USM"/>
        </a>
        <div onClick={handleDropdown} style={{ cursor: 'pointer' }}>
          <span className="fa-layers fa-fw fa-2xl">
            <FontAwesomeIcon icon="fa-solid fa-square" color="#005E90" transform="grow-6"/>
            <FontAwesomeIcon icon={dropdownOpen? 'fa-solid fa-xmark' : 'fa-solid fa-bars'} transform="shrink-2" inverse/>
          </span>
        </div>
      </div>
      <div className={dropdownOpen? 'dropdown-container' : 'dropdown-container inactive'}>
        <div
          className={'contt'}
          style={{ height: links.length * 60 * dropdownOpen }}
        >
          {links.map(link => (
            <NavLink
              exact
              key={link.url}
              to={dropdownOpen ? link.url : ''}
              onClick={handleClick}
              className={link.url === window.location.pathname ? 'dropdown-element current2' : 'dropdown-element'}
            >
              {link.title}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;