import React from 'react';
import '../body.css';
import './aboutus.css';

/*SECCION LABORATORIO*/

const AboutUs = () => {

  return (
    <>
      <div id="about-us" className="header-backg">
        <h1>
          Sobre Nosotros
        </h1>
      </div>
      <div className="main-body">
        <div className="content-container mv-container">
          <div className="mv-content">
            <p className="titulo" id='vision'>
              <a href='#vision' className='section-title'>Visión</a>
            </p>
            <div className="mv-detail en-linea">
              <div className="mv-icon-container">
                <img
                  className="mv-icon protected"
                  src={require('../../Images/SobreNosotros/Vision.png')}
                  alt="Ícono de visión"
                />
              </div>
              <div className="centrado-vertical">
                <p className="contenido">
                    Liderar el cambio hacia una cultura tecnológica centrada en el <b>bienestar de las personas</b> y la búsqueda
                    del <b>desarrollo sostenible e inclusivo</b> de la sociedad.
                </p>
              </div>
            </div>
          </div>
          <div className="mv-content">
            <p className="titulo" id='mision'>
              <a href='#mision' className='section-title'>Misión</a>
            </p>
            <div className="mv-detail en-linea">
              <div className="mv-icon-container">
                <img
                  className="mv-icon protected"
                  src={require('../../Images/SobreNosotros/Mision.png')}
                  alt="Ícono de misión"
                />
              </div>
              <div className="centrado-vertical">
                <p className="contenido">
                  Fortalecer la <b>formación e investigación multidisciplinaria</b> en el desarrollo y evaluación de
                  tecnologías centradas en las necesidades de las personas.
                </p>
              </div>
            </div>
          </div>

        </div>

        <div className="content-container">
          <p className="titulo" id='metodologia-lab-ux'>
            <a href='#metodologia-lab-ux' className='section-title' style={{ marginBottom: '10px' }}>
              Metodología Lab UX
            </a>
          </p>
          <div className='img-met-container'>
            <img
              src={require('../../Images/SobreNosotros/Metodologia-labux.png')}
              alt='Diagrama de la metodología del Laboratorio UX'
              className='img-met'
            />
          </div>
          <div className='container-centered'>
            <div style={{ maxWidth: '1200px' }}>
              <p className='contenido met-content'>
                La <b>Metodología del Lab UX</b> consiste en un proceso iterativo no lineal de 5 etapas para llevar a cabo
                <b> proyectos de diseño y evaluación</b>. El flujo principal comienza en la etapa de
                <b className='gold'> Planificar</b> y finaliza en la etapa de <b className='green'> Probar y Ajustar</b>,
                donde se obtienen resultados tangibles de la investigación.
                <br/>
                Durante cada etapa se realizan actividades previamente definidas, se aplican diferentes&nbsp;
                <b>Métodos de UX</b> y se evalúa si se debe
                continuar con el flujo esperado o volver a una etapa previa.
              </p>
              <div className='met-pasos'>
                <div style={{ borderColor: 'var(--color-gold)' }}>
                  <b className='gold'>Planificar</b>
                  <span>
                    En esta etapa inicial, los equipos deben definir un plan de trabajo y los objetivos que motivarán
                    la investigación.
                  </span>
                </div>
                <div style={{ borderColor: 'var(--color-orange)' }}>
                  <b className='orange'>Empatizar y Analizar</b>
                  <span>
                    Profunda comprensión de las necesidades de los usuarios y de su entorno. Se generan soluciones
                    consecuentes con sus realidades.
                  </span>
                </div>
                <div style={{ borderColor: 'var(--color-blue)' }}>
                  <b className='blue'>Idear</b>
                  <span>
                    Se generan de un sinfín de opciones. En esta fase, las actividades
                    favorecen el pensamiento y se eliminan los juicios de valor.
                  </span>
                </div>
                <div style={{ borderColor: 'var(--color-gray)' }}>
                  <b className='gray'>Prototipar</b>
                  <span>
                    En esta etapa se vuelven las ideas realidad. Construir prototipos hace las ideas palpables y
                    ayuda a visualizar las posibles soluciones.
                  </span>
                </div>
                <div style={{ borderColor: 'var(--color-green)' }}>
                  <b className='green'>Probar y Ajustar</b>
                  <span>
                    En la etapa final, se prueban los prototipos con usuarios reales. Se identifican oportunidades de mejora,
                    fallos a resolver y posibles carencias.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="historia-container" className="content-container">
          <p className="titulo" id='historia'>
            <a href='#historia' className='section-title'>Historia</a>
          </p>
          <div className='container-centered'>
            <div className="timeline">
              <div className="begin-timeline"></div>
              <div className="en-linea">
                <div className="time-container protected">
                  <img className="time" src={require('../../Images/SobreNosotros/timestamp1.png')} alt=""/>
                  <img className="time-s" src={require('../../Images/SobreNosotros/timestamp1s.png')} alt=""/>
                </div>
                <p className="contenido">
                  En <b className='orange'>enero de 2020</b>, la Universidad Técnica Federico Santa
                  María (USM) y Scotiabank lanzaron una <b> Alianza Académica para la Transformación Digital</b>.
                  <br/>
                  La iniciativa, liderada por el Departamento de Informática, busca fortalecer <b>5 líneas de trabajo</b>
                  : <u>Computación Centrada en Personas</u>, <u>Ciberseguridad</u>, <u>Inteligencia Artificial</u>, <u>Desarrollo
                  de Habilidades Blandas</u>, y <u>Mujeres en STEM</u>.
                </p>
              </div>
              <div className="en-linea">
                <div className="time-container protected">
                  <img className="time" src={require('../../Images/SobreNosotros/timestamp2.png')} alt=""/>
                  <img className="time-s" src={require('../../Images/SobreNosotros/timestamp2s.png')} alt=""/>
                </div>
                <p className="contenido">
                  Para idear el diseño del Lab UX y definir los requerimientos que debiera cumplir,
                  en <b className='orange'>marzo de 2020</b> se realizó un <b>diseño participativo</b> con
                  un <b>Equipo Asesor Externo</b> de ex-estudiantes de la USM que se desempeñan en el área de UX.
                </p>
              </div>
              <div className="en-linea">
                <div className='time-multiple'>
                  <div className="time-container protected">
                    <img className="time" src={require('../../Images/SobreNosotros/timestamp3.png')} alt=""/>
                    <img className="time-s" src={require('../../Images/SobreNosotros/timestamp3s.png')} alt=""/>
                  </div>
                  <div className="time-container protected">
                    <img className="time" src={require('../../Images/SobreNosotros/timestamp4.png')} alt=""/>
                    <img className="time-s" src={require('../../Images/SobreNosotros/timestamp4s.png')} alt=""/>
                  </div>
                </div>
                <div className='time-multiple'>
                  <p className="contenido">
                    Durante el <b className='green'>primer semestre de 2021</b>, se trabajó con profesores,
                    estudiantes y memoristas, tanto de Ingeniería Civil Informática como de Ingeniería en Diseño de Productos,
                    para <b>concretar el diseño</b> del Laboratorio.
                  </p>
                  <p className="contenido">
                    En el <b className='green'>segundo semestre del mismo año</b> se termina de construir
                    el Lab UX USM en <b>Casa Central</b> (CC), con espacios y tecnología de vanguardia.
                  </p>
                </div>
              </div>
              <div className="en-linea">
                <div className='time-multiple'>
                  <div className="time-container protected">
                    <img className="time" src={require('../../Images/SobreNosotros/timestamp5.png')} alt=""/>
                    <img className="time-s" src={require('../../Images/SobreNosotros/timestamp5s.png')} alt=""/>
                  </div>
                  <div className="time-container protected">
                    <img className="time" src={require('../../Images/SobreNosotros/timestamp6.png')} alt=""/>
                    <img className="time-s" src={require('../../Images/SobreNosotros/timestamp6s.png')} alt=""/>
                  </div>
                </div>
                <p className="contenido">
                  En <b style={{ color: 'var(--color-red)' }}>marzo de 2022</b> se inaugura el Lab UX USM CC, donde se realiza
                  una <b>ceremonia con todas las autoridades</b> de la USM presentes. El Laboratorio comienza a funcionar
                  con su primer equipo de ayudantes en este semestre, dándose a conocer a la comunidad estudiantil USM.
                  <br/>
                  Durante el <b style={{ color: 'var(--color-red)' }}>segundo semestre del 2022</b>, se comienza el trabajo
                  de diseñar el <b>Lab UX en el Campus San Joaquín</b> (SJ), con el fin de ampliar el alcance e impacto
                  del Laboratorio a más estudiantes.
                </p>
              </div>
              <div className="en-linea">
                <div className="time-container protected">
                  <img className="time" src={require('../../Images/SobreNosotros/timestamp7.png')} alt=""/>
                  <img className="time-s" src={require('../../Images/SobreNosotros/timestamp7s.png')} alt=""/>
                </div>
                <p className="contenido">
                  El <b className='blue'>año 2023</b> ve un avance en la <b>construcción del Lab UX USM
                  SJ</b>. La experiencia aprendida en Casa Central prueba ser fundamental para construir un espacio adecuado
                  con las capacidades para realizar evaluaciones y proyectos enfocados en usuarios.
                </p>
              </div>
              <div className="en-linea" style={{ alignItems: 'center' }}>
                <div className="time-container protected">
                  <img className="time" src={require('../../Images/SobreNosotros/timestamp8.png')} alt=""/>
                  <img className="time-s" src={require('../../Images/SobreNosotros/timestamp8s.png')} alt=""/>
                </div>
                <p className="contenido">
                  En <b className='gold'>octubre de 2024</b> se <b>inaugura el Lab UX SJ</b>, representando
                  un hito importante en el avance de la investigación y el desarrollo en diseño centrado en usuarios.
                </p>
              </div>
              <div className="end-timeline"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;