// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

html {
  --color-dark: #30343F;
  --color-gray: #939597;
  --color-orange: #DC793A;
  --color-blue: #5D89B4;
  --color-yellow: #F8DE8D;
  --color-green: #9bc74b;
  --color-gold: #DAAC1F;
  --color-red: #E83B7B;

  --color-usm-dark: #3b424c;
  --color-usm-blue: #005E90;
  --color-usm-blue-dark: #074469;
  --color-usm-yellow: #E5B300
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,mCAAmC;EACnC,kBAAkB;EAClB,mCAAmC;AACrC;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,uBAAuB;EACvB,qBAAqB;EACrB,uBAAuB;EACvB,sBAAsB;EACtB,qBAAqB;EACrB,oBAAoB;;EAEpB,yBAAyB;EACzB,yBAAyB;EACzB,8BAA8B;EAC9B;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');\n\nbody {\n  margin: 0;\n  font-family: Montserrat, sans-serif;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n}\n\nhtml {\n  --color-dark: #30343F;\n  --color-gray: #939597;\n  --color-orange: #DC793A;\n  --color-blue: #5D89B4;\n  --color-yellow: #F8DE8D;\n  --color-green: #9bc74b;\n  --color-gold: #DAAC1F;\n  --color-red: #E83B7B;\n\n  --color-usm-dark: #3b424c;\n  --color-usm-blue: #005E90;\n  --color-usm-blue-dark: #074469;\n  --color-usm-yellow: #E5B300\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
